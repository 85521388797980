import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

// import '../assets/sass/libs/bootstrap/css/bootstrap.min.css';
import '../assets/sass/main.scss';

import Footer from './Footer';
import SideBar from './Sidebar';

class Layout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPreloaded: true,
        };
    }

    componentDidMount() {
        this.timeoutId = setTimeout(() => {
            this.setState({ isPreloaded: false });
        }, 100);
    }

    componentWillUnmount() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    }

    render() {
        const { children, fullMenu } = this.props;
        const { isPreloaded } = this.state;
        return (
            <StaticQuery
                query={graphql`
                    query SiteTitleQuery {
                        site {
                            siteMetadata {
                                title
                            }
                        }
                    }
                `}
                render={data => (
                    <>
                        <Helmet
                            title={data.site.siteMetadata.title}
                            meta={[
                                {
                                    name: 'description',
                                    content: 'Cinevista Tours',
                                },
                                { name: 'keywords', content: 'site, web' },
                            ]}
                        >
                            <html lang="en" />
                        </Helmet>
                        <div
                            className={
                                isPreloaded
                                    ? ' main-body  is-preload'
                                    : ' main-body'
                            }
                        >
                            <div id="page-wrapper">
                                <SideBar fullMenu={fullMenu} />
                                {children}
                                <Footer />
                            </div>
                        </div>
                    </>
                )}
            />
        );
    }
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
